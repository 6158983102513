import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const EmailLink = ({ email, children, triggerTrackingEvent }) => {
	const labels = useLabels();

	const emailAriaLabel = `${labels.get('SEND_AN_EMAIL_TO')} ${email}`;

	const onClick = () => {
		triggerTrackingEvent({
			element: 'rich content autocomplete result',
			elementCTA: children || email,
			result: 'navigated to rich content destination'
		});
	};

	return (
		<li>
			<a
				href={`mailto:${email}`}
				target="_blank"
				rel="noopener noreferrer"
				className="btn btn-default btn-block"
				onClick={onClick}
				aria-label={emailAriaLabel}
			>
				<i
					className="ddc-icon ddc-icon-email mr-3"
					aria-hidden="true"
				/>
				{children || email}
			</a>
		</li>
	);
};

EmailLink.propTypes = {
	email: PropTypes.string.isRequired,
	children: PropTypes.string,
	triggerTrackingEvent: PropTypes.func
};
EmailLink.defaultProps = {
	children: '',
	triggerTrackingEvent() {}
};
export default EmailLink;
