import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import Chip from './Chip';

const RecentSearches = ({
	recentSearches,
	handleClearHistory,
	chipAriaLabel,
	removeAriaLabel,
	onRemove,
	onReorder,
	handleChipClick
}) => {
	const labels = useLabels();

	return (
		<div>
			<div className="d-flex justify-content-between align-items-center">
				<h2
					id="recent-searches-heading text-capitalize"
					className="ddc-heading-3 font-weight-bold mt-0"
				>
					{labels.get('RECENT_SEARCHES')}
				</h2>
				<button
					className="btn btn-link p-0 text-danger"
					style={{
						cursor: 'pointer',
						fontWeight: 'lighter',
						fontSize: '0.8rem'
					}}
					onClick={handleClearHistory}
					type="button"
					aria-label={labels.get('CLEAR_SEARCH_HISTORY')}
				>
					{labels.get('CLEAR_HISTORY')}
				</button>
			</div>
			{recentSearches?.slice(0, 5).map((recentSearch) => (
				<Chip
					key={recentSearch.id}
					recentSearch={recentSearch}
					btnLabel={chipAriaLabel}
					removeLabel={removeAriaLabel}
					onRemove={onRemove}
					onReorder={onReorder}
					handleChipClick={handleChipClick}
				/>
			))}
		</div>
	);
};

RecentSearches.propTypes = {
	recentSearches: PropTypes.arrayOf(PropTypes.shape({})),
	handleClearHistory: PropTypes.func,
	chipAriaLabel: PropTypes.string,
	removeAriaLabel: PropTypes.string,
	onRemove: PropTypes.func,
	onReorder: PropTypes.func,
	handleChipClick: PropTypes.func
};

export default RecentSearches;
