import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const PhoneLink = ({
	department,
	number,
	accountRef = '',
	phoneRef = '',
	triggerTrackingEvent = () => {}
}) => {
	const anchorRef = useRef();
	const labels = useLabels();

	useEffect(() => {
		// may be able to use loadNumbers(node) if we can get the parent container of the phone numbers
		// that may be better performance
		if (
			typeof window.phoneSwapping !== 'undefined' &&
			typeof window.phoneSwapping.domTraverse !== 'undefined'
		) {
			window.phoneSwapping.domTraverse(
				anchorRef.current,
				window.phoneSwapping.swapNumbers
			);
		}
	}, []);

	const onClick = () => {
		triggerTrackingEvent({
			element: 'rich content autocomplete result',
			elementCTA: `${department}: ${number}`,
			result: 'navigated to rich content destination'
		});
	};

	const departmentLabel = labels.get(department.toUpperCase());
	const srOnlyText = ` ${labels.get('DEPARTMENT')} ${labels.get('NUMBER')}`;

	return (
		<li>
			<a
				href={`tel:${number.replace(new RegExp('-()', 'g'), '')}`}
				className="btn btn-default btn-block"
				title={`${labels.get('CLICK_TO_CALL_OUR')} ${labels.get(
					departmentLabel
				)} ${labels.get('DEPARTMENT')}.`}
				data-account-ref={accountRef}
				data-phone-ref={phoneRef}
				onClick={onClick}
				ref={anchorRef}
			>
				<i className="ddc-icon ddc-icon-call mr-3" aria-hidden="true" />
				<span>
					{labels.get(departmentLabel)}
					<span className="sr-only">{srOnlyText}</span>: {number}
				</span>
				{/* The outer span is needed for number swapping (call tracking) to work */}
			</a>
		</li>
	);
};

PhoneLink.propTypes = {
	department: PropTypes.string.isRequired,
	number: PropTypes.string.isRequired,
	accountRef: PropTypes.string,
	phoneRef: PropTypes.string,
	triggerTrackingEvent: PropTypes.func
};
export default PhoneLink;
