import React from 'react';
import PropTypes from 'prop-types';
import { useLabels, usePrefs } from 'wsm-common-data';

import VehicleCard from './VehicleCard';

const VehicleCards = ({ vehicles, handleLinkClick }) => {
	const labels = useLabels();
	const { showVehicleCards, vehicleCardImage } = usePrefs();

	if (showVehicleCards === 'true' && vehicles?.length > 0) {
		let keyCount = 0;
		return (
			<div
				data-testid="modern-vehicle-cards"
				role="region"
				aria-labelledby="vehicle-cards-heading"
			>
				<h2 id="vehicle-cards-heading" className="sr-only">
					{labels.get('VEHICLE_SEARCH_RESULTS')}
				</h2>
				{vehicles.slice(0, 5).map((vehicle) => {
					const {
						path,
						images: [image1, image2],
						title,
						price,
						odometer,
						odometerUnit,
						condition
					} = vehicle;

					const titleString = Array.isArray(title)
						? title.join(' ')
						: title;

					const image =
						image2 && vehicleCardImage === 'secondary'
							? image2
							: image1;
					const capitalizedCondition = condition.toUpperCase();
					keyCount += 1;
					return (
						<VehicleCard
							href={path || null}
							imageUrl={image.url}
							imageAlt={titleString}
							key={keyCount}
							title={titleString}
							price={price}
							odometer={odometer}
							odometerUnit={odometerUnit}
							condition={capitalizedCondition}
							handleLinkClick={handleLinkClick}
						/>
					);
				})}
			</div>
		);
	}
	return null;
};

VehicleCards.propTypes = {
	vehicles: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string,
			title: PropTypes.arrayOf(PropTypes.string),
			images: PropTypes.arrayOf(PropTypes.shape({})),
			price: PropTypes.string,
			odometer: PropTypes.string,
			odometerUnit: PropTypes.string,
			condition: PropTypes.string
		})
	),
	handleLinkClick: PropTypes.func
};

export default VehicleCards;
