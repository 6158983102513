import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { usePrefs, useLabels } from 'wsm-common-data';
import ResultsHeading from './ResultsHeading';
import PhoneLink from './PhoneLink';
import EmailLink from './EmailLink';

const ContactLinksList = ({
	phoneNumbers = [],
	email = '',
	richContentToDisplay = [],
	labelSize = 5,
	ariaLabelledById = '',
	triggerTrackingEvent = () => {}
}) => {
	const { accountId } = useSelector((state) => state.widgetData);
	const { contactUsHeadingLabel } = usePrefs();
	const labels = useLabels();

	const links = richContentToDisplay.reduce((linksArray, richContentName) => {
		const [richContentType, richContentDepartment] =
			richContentName.split('-');
		if (richContentType === 'phone' && richContentDepartment) {
			const phoneObject = phoneNumbers.find(
				(i) =>
					i.dept ===
					richContentDepartment.charAt(0).toUpperCase() +
						richContentDepartment.slice(1)
			);
			if (phoneObject?.number) {
				const { number, dept, type } = phoneObject;
				linksArray.push(
					<PhoneLink
						department={dept}
						number={number}
						key={`phone-${dept}`}
						accountRef={accountId}
						phoneRef={type.toUpperCase()}
						triggerTrackingEvent={triggerTrackingEvent}
					/>
				);
			}
		} else if (richContentType === 'email') {
			if (email) {
				linksArray.push(
					<EmailLink
						email={email}
						triggerTrackingEvent={triggerTrackingEvent}
						key={email}
					>
						{labels.get('SEND_US_AN_EMAIL')}
					</EmailLink>
				);
			}
		}
		return linksArray;
	}, []);

	const headingLabel = labels.get(contactUsHeadingLabel);
	const optionalAttributes = {
		...(headingLabel && { 'aria-labelledby': ariaLabelledById })
	};

	return links.length ? (
		<div {...optionalAttributes}>
			{headingLabel && (
				<ResultsHeading
					label={headingLabel}
					labelSize={labelSize}
					ariaLabelledById={ariaLabelledById}
				/>
			)}
			<ul
				role="navigation"
				className="list-unstyled list-spaced mx-4 mb-4"
			>
				{links}
			</ul>
		</div>
	) : null;
};

ContactLinksList.propTypes = {
	phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
	email: PropTypes.string,
	richContentToDisplay: PropTypes.arrayOf(PropTypes.string),
	labelSize: PropTypes.number,
	ariaLabelledById: PropTypes.string,
	triggerTrackingEvent: PropTypes.func
};
export default ContactLinksList;
