import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import { g } from '../../../global-constants';

const ResultsHeading = ({ label, labelSize = 3, ariaLabelledById }) => {
	const { display } = usePrefs();
	const labelAttributes = {
		id: ariaLabelledById,
		className: `ddc-font-size-small ${
			display === g.EMBEDDED ? 'widget-heading' : ''
		}`.trim()
	};
	return React.createElement(`h${labelSize}`, labelAttributes, label);
};

ResultsHeading.propTypes = {
	label: PropTypes.string.isRequired,
	ariaLabelledById: PropTypes.string.isRequired,
	labelSize: PropTypes.number
};

export default ResultsHeading;
